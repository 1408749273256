import React, { useEffect, useState } from 'react'

const Game = (props) => {

  const { iframeUrl, mobileScreenHeight } = props

  const [iframeWidth, setIframeWidth] = useState(window.innerWidth);
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight);

  useEffect(() => {
    // console.log(mobileScreenHeight)
    const handleResize = () => {
      setIframeWidth(window.innerWidth);
      setIframeHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <iframe
      title="Antareeksh"
      src={iframeUrl}
      allow="microphone"
      style={{
        width: `${iframeWidth}px`,
        height: `${mobileScreenHeight.current}px`,
        border: "none",
        zIndex: "999",
      }}
    />
  )
}

export default Game