import React, { useState, useEffect, useRef } from 'react'
import io from "socket.io-client";

import Welcome from './Welcome'
import Auth from './Auth'
import Loading from './Loading';
import Game from './Game';

const Start = () => {

  // const url = "http://localhost:5000";
  const url = "https://api.antareeksh.xrvizion.com/";
  // const url = "https://api.antareeksh.demo.xrvizion.com/";

  const [serverUrl, setServerUrl] = useState("");
  const [serverReadyToConnect, setServerReadyToConnect] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [formSubmitted, setIsFormSubmitted] = useState(false)
  const [invokeAuth, setInvokeAuth] = useState(false)
  const [gameUrl, setGameUrl] = useState("")
  const mobileScreenHeight = useRef(0)

  const [firstSocket, setFirstSocket] = useState(null);
  const [secondSocket, setSecondSocket] = useState(null);

  // const showSkipBtn = useRef(false)
  const [showSkipBtn, setShowSkipBtn] = useState(false)

  useEffect(() => {
    mobileScreenHeight.current = window.innerHeight
  }, [])

  useEffect(() => {
    const socket = io.connect(url);
    setFirstSocket(socket);

    const handleConnect = () => {
      console.log("websocket connected", socket.id);
    };

    const handleServerStarted = (serverLink) => {
      console.log("server started", serverLink);
      setServerUrl(serverLink);
    };

    const handleMaxInstanceLimitReached = () => {
      console.log("MaxInstanceLimitReached");
      handleRetry()
    };

    const handleRetry = () => {
      setTimeout(() => {
        console.log("retrying");
        socket.disconnect();
        socket.connect();
      }, 3000);
    };

    socket.on("connect", handleConnect);
    socket.on("serverStarted", handleServerStarted);
    socket.on("MaxInstanceLimitReached", handleMaxInstanceLimitReached);
    socket.on("retry", handleRetry);

    // // Add an effect to handle disconnection of the first WebSocket
    // if (firstSocket) {
    //   firstSocket.on("disconnect", () => {
    //     console.log("First WebSocket disconnected.");
    //     if (secondSocket && secondSocket.connected) {
    //       console.log("Disconnecting second WebSocket.");
    //       secondSocket.disconnect();
    //     }
    //   });
    // }

    return () => {
      socket.disconnect(); // Cleanup function: disconnect WebSocket
    };
  }, []);

  useEffect(() => {
    let isMounted = true; // To track component unmounting
    let timerId;

    const checkUrlAccessibility = async (url) => {
      console.warn(url)
      try {
        const response = await fetch(url);
        console.warn("response", response)
        if (response.ok) {
          // URL is accessible
          console.log("URL is accessible");
          if (isMounted) {
            setGameUrl(url);
            setTimeout(() => {
              // setIsLoading(false);
              // showSkipBtn.current = true
              setShowSkipBtn(true)
              // console.log("SKIP BUTTON")
            }, 5000);
          }
        } else {
          console.log("URL is not accessible. Retrying...");
          // Retry after some delay
          timerId = setTimeout(() => checkUrlAccessibility(url), 2000); // Retry after 2 seconds
        }
      } catch (error) {
        console.error("Error accessing URL:", error);
        // Retry after some delay
        timerId = setTimeout(() => checkUrlAccessibility(url), 2000); // Retry after 2 seconds
      }
    };

    if (serverUrl) {
      const urlToCheck = serverUrl + ":5001";
      checkUrlAccessibility(urlToCheck);
    }

    return () => {
      isMounted = false; // Component is unmounting, clear the flag
      clearTimeout(timerId); // Clear any pending retries
    };
  }, [serverUrl])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(serverUrl);
  //       const data = await response.text();
  //       console.log("response", data);
  //       if (data === "Running") {
  //         setServerReadyToConnect(true);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   if (serverUrl && !serverReadyToConnect) {
  //     const interval = setInterval(fetchData, 5000); // Adjust interval as needed
  //     return () => clearInterval(interval);
  //   }
  // }, [serverUrl, serverReadyToConnect]);

  // useEffect(() => {
  //   let isMounted = true; // To track component unmounting
  //   let timerId;

  //   const checkUrlAccessibility = async (url) => {
  //     console.warn(url)
  //     try {
  //       const response = await fetch(url);
  //       console.warn("response", response)
  //       if (response.ok) {
  //         // URL is accessible
  //         console.log("URL is accessible");
  //         if (isMounted) {
  //           setGameUrl(url);
  //           setTimeout(() => {
  //             // setIsLoading(false);
  //             // showSkipBtn.current = true
  //             setShowSkipBtn(true)
  //             // console.log("SKIP BUTTON")
  //           }, 5000);
  //         }
  //       } else {
  //         console.log("URL is not accessible. Retrying...");
  //         // Retry after some delay
  //         timerId = setTimeout(() => checkUrlAccessibility(url), 2000); // Retry after 2 seconds
  //       }
  //     } catch (error) {
  //       console.error("Error accessing URL:", error);
  //       // Retry after some delay
  //       timerId = setTimeout(() => checkUrlAccessibility(url), 2000); // Retry after 2 seconds
  //     }
  //   };

  //   if (serverReadyToConnect) {
  //     // Code to establish WebSocket connection
  //     const socket = io.connect(serverUrl);
  //     setSecondSocket(socket);

  //     socket.on("connect", () => {
  //       console.log("websocket connected 2", socket.id);
  //     });

  //     socket.on("URL", (iurl) => {
  //       const urlToCheck = serverUrl + ":" + iurl;
  //       checkUrlAccessibility(urlToCheck);
  //     });

  //     socket.on("crashed", () => {
  //       setTimeout(() => {
  //         console.log("crashed, retrying");
  //         socket.disconnect();
  //         socket.connect();
  //       }, 2000);
  //     });

  //     // Add an effect to handle disconnection of the first WebSocket
  //     // if (secondSocket) {
  //     //   secondSocket.on("disconnect", () => {
  //     //     console.log("Second WebSocket disconnected.");
  //     //     if (firstSocket && firstSocket.connected) {
  //     //       console.log("Disconnecting second WebSocket.");
  //     //       firstSocket.disconnect();
  //     //     }
  //     //   });
  //     // }

  //     return () => {
  //       isMounted = false; // Component is unmounting, clear the flag
  //       clearTimeout(timerId); // Clear any pending retries
  //       socket.disconnect();
  //     };
  //   }
  // }, [serverReadyToConnect, serverUrl, setIsLoading]);

  useEffect(() => {
    // Add event listener for beforeunload
    const handleBeforeUnload = () => {
      if (firstSocket) {
        // localStorage.setItem("msg1", "Cleaning up first WebSocket before page unload...");
        firstSocket.close();
      }
      if (secondSocket) {
        // localStorage.setItem("msg2", "Cleaning up second WebSocket before page unload...");
        secondSocket.close();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [firstSocket, secondSocket]);


  const enterGame = () => {
    setIsLoading(false)
    // showSkipBtn.current = false
    setShowSkipBtn(false)
  }

  return (
    <div className='container'>
      {formSubmitted ? (
        isLoading && <Loading />
      ) : (
        invokeAuth ? <Auth setIsFormSubmitted={setIsFormSubmitted} mobileScreenHeight={mobileScreenHeight} /> : <Welcome setInvokeAuth={setInvokeAuth} setIsFormSubmitted={setIsFormSubmitted} />
      )}

      {(formSubmitted && gameUrl.length > 0) && <Game iframeUrl={gameUrl} mobileScreenHeight={mobileScreenHeight} />}

      {
        (formSubmitted && showSkipBtn) && <button className='start-button skip-btn ff-sb fs-sm' onClick={enterGame}>Skip</button>
      }

    </div>
  )
}

export default Start