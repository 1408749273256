import React, { useEffect, useState } from 'react'

const Auth = (props) => {

  const { setIsFormSubmitted, mobileScreenHeight } = props

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")

  const handleSubmit = () => {
    // console.log("form submitted")
    setIsFormSubmitted(true)
  }

  const updateLoadingScreenHeight = () => {
    const loadingScreen = document.querySelector('.loading-screen');
    if (loadingScreen) {
      loadingScreen.style.height = `${mobileScreenHeight.current}px`;
      window.scrollTo(0, 0);
    }
  };

  // Call the function on page load
  useEffect(() => {
    updateLoadingScreenHeight();
    window.addEventListener('resize', updateLoadingScreenHeight);

    return () => {
      window.removeEventListener('resize', updateLoadingScreenHeight);
    };
  }, []);

  const handleInputBlur = () => {
    updateLoadingScreenHeight(); // Adjust loading screen height when input loses focus
  };

  return (
    <div className='loading-screen'>
      <div className="load-start-cont" style={{justifyContent: "center"}}>
        <div className='ff-m fs-xl title-text'>Welcome to Antareeksh</div>
        <div className='ff-r fs-sm subtitle-text'>Unlock the experience: Enter your name and email to begin!</div>
        <div className='note-cont form-cont'>
          <input onBlur={handleInputBlur} type='text' className='text-input ff-m fs-sm' value={name} onChange={(event) => setName(event.target.value)} placeholder='Name'></input>
          <input onBlur={handleInputBlur} type='email' className='text-input ff-m fs-sm'value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email'></input>
          {/* <input type='text' className='text-input ff-m fs-sm' value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder='Mobile'></input> */}
        </div>
        <button className='start-button ff-sb fs-sm' onClick={handleSubmit} disabled={!email || !name}>Submit</button>
      </div>
      <div className="xrv-logo">
        <div className='xrv-logo-text ff-l fs-xs'>powered by</div>
        <img className="xrv-logo-img" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRVizion/xrv.png" alt=""></img>
      </div>
    </div>
  )
}

export default Auth