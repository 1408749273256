import React, { useState, useEffect, useRef } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

const Loading = () => {
  // S3 URLs for the videos
  const mobileVideoUrl = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRVizion/Jain+Housing/Intro+portrait+2.MP4';
  const desktopVideoUrl = 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/Antareeksh/Resources/JHLoadingDesktop.mp4';

  // Determine which video to use based on screen size
  const videoSource = useRef(window.innerWidth > 768 ? desktopVideoUrl : mobileVideoUrl);
  const [, forceUpdate] = useState();

  const updateVideoSource = () => {
    const newVideoSource = window.innerWidth > 768 ? desktopVideoUrl : mobileVideoUrl;
    if (videoSource.current !== newVideoSource) {
      videoSource.current = newVideoSource;
      forceUpdate(Math.random()); // Trigger a re-render
    }
  };

  const updateLoadingScreenHeight = () => {
    const loadingScreen = document.querySelector('.loading-screen');
    if (loadingScreen) {
      loadingScreen.style.height = window.innerHeight + 'px';
    }
  };

  useEffect(() => {
    updateLoadingScreenHeight();
    updateVideoSource();
    window.addEventListener('resize', updateLoadingScreenHeight);
    window.addEventListener('resize', updateVideoSource);

    // Listen for changes in the visual viewport size
    const handleResize = () => {
      updateLoadingScreenHeight();
    };
    window.visualViewport.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', updateLoadingScreenHeight);
      window.removeEventListener('resize', updateVideoSource);
      window.visualViewport.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className='overlay-alt'></div>
      <div className='loading-screen' style={{ position: "relative", display: 'flex', justifyContent: "center", zIndex: "10000" }}>
        <video
          autoPlay
          loop
          playsInline // Play inline on iOS
          preload="auto" // Start loading immediately
          style={{ position: "absolute", width: "100%", height: "100%", objectFit: "cover" }}
        >
          <source src={videoSource.current} type="video/mp4" />
        </video>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", zIndex: 1 }}>
          <PuffLoader
            color="white"
            size={window.innerWidth > 768 ? 100 : 70}
          />
        </div>
      </div>
    </>
  );
};

export default Loading;
