import { useEffect } from 'react';
import './App.css';
import Start from './Components/Start';

function App() {

  useEffect(() => {
    const handleResize = () => {
      const overlayDiv = document.querySelector('.overlay');
      overlayDiv.style.height = `${window.innerHeight}px`;
      document.body.style.height = `${window.innerHeight}px`;
    };

    // Set initial heights when the component mounts
    handleResize();

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.height = ''; // Reset body height
    };
  }, []);

  return (
    <>
      <div className='overlay'></div>
      <Start />
    </>
  );
}

export default App;
